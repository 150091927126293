<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import SystemValues from "@/components/system-values";
import { required } from "vuelidate/lib/validators";
import { parseErrors } from '../../../helpers'
import Swal from "sweetalert2";
import http from '@/http'


import {
   
} from "@/state/helpers";


export default {
  components: {
    Layout,
    PageHeader,
    SystemValues
  },
  data() {
    
    return {
      title: this.$t("pages.instruments.stocks"),
      showmodal: false,
      editedItemId: null,
      submitted: false,
      editedItem: {
        name:null,
        displayName:null,
        description: null
      },
      items:[],
      errors:[],
      isBusy: false,
      keyword: null,
      selectedKey: null,
      saving: null,
      keys:[],
      selectedKeyValue: null,
      keysLoading: false,
      name: null,
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      breadcrumbs: [
        
        {
          text: this.$t("pages.instruments.title"),
        },
        {
          text: this.$t("pages.instruments.stocks"),
          active: true
        }
      ]
    };
  },
  validations: {
    editedItem: {
      displayName: { required },
      name: { required }
    }
  },
  created() {
    this.loadKeys();
    this.load();
  },
  watch: {
    currentPage: {
      handler: function () {
        this.load();
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    }
  },
  methods: {
     async applyFilters(){
      this.currentPage = 1;
      this.load();
    },
    async loadKeys(){
       this.keysLoading = true;
      this.selectedKeyValue = null;
      this.selectedKey = null;
      const resp = await http.get(`api/stocks/system-values/keys`);
      this.keys = resp.data;
       this.keysLoading = false;
    },
    async load()  {
      this.isBusy = true;
    
      let sv = {};
      if(this.selectedKey){
        sv[this.selectedKey] = this.selectedKeyValue;
      }
      const resp = await http.post(`api/stocks/search`, {
        take: this.perPage,
        skip: this.perPage * (this.currentPage - 1),
        keyword:  this.keyword,
        name: this.name,
        systemValues:sv
      });
      this.items = resp.data.items;
      this.totalItems = resp.data.total;
      this.isBusy = false;  
    },
   
    hideModal() {
      this.processing = false;
      this.showmodal = false;
      this.submitted = false;
      this.editedItem = {};
      this.errors = [];
    },
    showModal(item) {
      this.processing = false;
      this.showmodal = true;
      this.submitted = false;
      this.errors = [];
      this.editedItem = item ? {...item} : 
      {
        
      };
      this.editedItemId = item ? item.id : null;
    },
    handleSubmit() {
      this.submitted = true;
      this.errors = [];
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.saving = true;
        const dto = {...this.editedItem, id: this.editedItemId };
        const req = !this.editedItemId ? http.post('api/stocks', dto) : http.put('api/stocks', dto);
        req.then(() => {
            this.saving = false;
          this.hideModal();
          this.load();
        })
        .catch(error => {
          this.saving = false;
          this.errors = parseErrors(error);
        });
      }
    },
    confirmDelete(item) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete(item);
        }
      });
    },
    handleDelete(item) {
     http.delete(`api/stocks/${item.id}`).then(() => {
        this.hideModal();
          Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
          this.load();
      })
      .catch(error => {
        this.errors= parseErrors(error);
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <!-- Right Sidebar -->
      <div class="col-12">
        <div class="email-leftbar card cardc8 p-0">
        <div class="card-content m-3">
          <b-form-group
            class="mt-2"
            :label="$t('pages.instruments.keyword')"
            label-for="type"
          >
            <div class="search-box">
              <div class="position-relative">
                <input type="text" class="form-control rounded" v-model="keyword" :placeholder="$t('pages.instruments.keyword')" id="keywords"/>
                <i class="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </b-form-group>

           <b-form-group
            class="mt-2"
            :label="$t('pages.instruments.name')"
            label-for="type"
          >
            <div class="search-box">
              <div class="position-relative">
                <input type="text" class="form-control rounded" v-model="name" :placeholder="$t('pages.instruments.name')"  id="name"/>
                <i class="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            class="mt-2"
            label-for="type"
          >
            <template v-slot:label>
              {{$t('pages.instruments.key')}}&nbsp;<i v-if="keysLoading" class="fa fa-spin fa-spinner"></i>
            </template>
            <select class="form-control" v-model="selectedKey">
              <option :value="null">--- Select key ---</option>
              <option v-for="t in keys" :value="t" :key="t">
               {{t}}
              </option>
            </select>
          </b-form-group>
          
          <b-form-group
            v-if="selectedKey"
            class="mt-2"
            :label="$t('pages.instruments.value')"
            label-for="value"
          >
            <div class="search-box">
              <div class="position-relative">
                <input type="text" class="form-control rounded" v-model="selectedKeyValue" :placeholder="$t('pages.instruments.value')" id="value"/>
                <i class="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </b-form-group>
          <hr/>
           <b-form-group>
            <button class="btn btn-block btn-secondary" @click="applyFilters">{{$t("common.applyFilters")}}</button>
          </b-form-group>
        </div>
        </div>
        <div class="email-rightbar mb-2">
          <div class="card p-0 cardc8">
          <div class="card-content m-3">
            <div class="form-group ">
             <b-button variant="secondary" class="mb-2" @click="showModal()">{{$t("common.newRecord")}}</b-button>
            </div>

            <div class="table-responsive" style="position: relative; min-height:300px">
              <table
                class="table table-centered nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead>
                  <tr>
                    <th style="width: 30px;max-width:30px;"></th>
                    <th>{{$t("tables.instruments.columns.name")}}</th>
                  
                    <th>{{$t("tables.instruments.columns.issueDate")}}</th>
                    <th>{{$t("tables.instruments.columns.countryOfIssue")}}</th>
                    <th>{{$t("tables.instruments.columns.issuer")}}</th>
                    <th>{{$t("tables.instruments.columns.priceIncrement")}}</th>
                     <th v-if="selectedKey">{{selectedKey}}</th>
                    <th>{{$t("tables.instruments.columns.description")}}</th>
                    <th style="width: 30px;max-width:30px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="items.length === 0 && isBusy !== true">
                    <td colspan="100">
                      <span class="text-muted">{{$t("common.noRecordsFound")}}</span>
                    </td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                   <td>
                      <a
                        v-if="!item.isSystem"
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        @click="showModal(item)"
                        
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    </td> 
                   
                   <td>
                      <span class="mr-1">{{item.name}}</span>
                      <em class="text-info small" v-if="item.name !== item.displayName">[{{item.displayName}}]</em>
                    </td>
                    <td>{{item.issueDate | moment("ddd, MMM Do YYYY") }}</td>
                    <td>{{item.countryOfIssue}}</td>
                    <td>{{item.issuer}}</td>
                    <td>{{item.priceIncrement}}</td>
                    <td v-if="selectedKey">
                      {{item.systemValues[selectedKey]}}
                      <em class="text-muted small" v-if="!item.systemValues[selectedKey]">{{$t("common.noValue")}}</em>
                    </td>  
                    <td>{{item.description}}</td>
                      <td>
                      <a
                        v-if="!item.isSystem"
                        href="javascript:void(0);"
                        class="mr-3 text-danger"
                        :disabled="saving"
                        @click="confirmDelete(item)"
                        title="Delete"
                      >
                        <i class="fa fa-times font-size-18"></i>
                      </a>
                    </td> 
                  </tr>
                </tbody>
              </table>
              <div style="position:absolute; left:0; right:0; top:0; bottom:0; background-color: rgba(255,255,255,0.5); text-align: center; padding-top:10%;"
                v-if="isBusy">
                <span>
                  <i class="fa fa-spin fa-spinner" style="font-size:3rem"></i>
                </span>
              </div>
            </div>
            </div>
          </div>
           <div class="row justify-content-md-between align-items-md-center">
            <div class="col-xl-7">{{$t('common.totalRecords')}} {{totalItems}}</div>
            <!-- end col-->
            <div class="col-xl-5">
              <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  @input="load"
                ></b-pagination>
              </div>
            </div>
            <!-- end col-->
          </div>
        </div>
      </div>
    </div>

   <!-- Modal -->
    <b-modal
      scrollable
      v-model="showmodal"
      :title="editedItemId ? $t('common.editRecord') :  $t('common.newRecord')"
      title-class="text-dark font-18"
      size="lg"
      hide-footer
    >
      <form @submit.prevent="handleSubmit" autocomplete="off">
         <b-alert
          variant="danger"
          class="mt-3"
          v-if="errors.length > 0"
          show
          dismissible
          >
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="name">{{$t('forms.stock.name')}} [*]</label>
              <input
                id="name"
                v-model="editedItem.name"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ 'is-invalid': submitted && $v.editedItem.name.$error }"
              />
              <div
                v-if="submitted && !$v.editedItem.name.required"
                class="invalid-feedback"
              >{{$t('forms.stock.name-required')}}</div>
            </div>
          </div>
           <div class="col-md-4">
              <div class="form-group">
              <label for="displayName">{{$t('forms.stock.displayName')}} [*]</label>
              <input
                id="displayName"
                v-model="editedItem.displayName"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ 'is-invalid': submitted && $v.editedItem.displayName.$error }"
              />
              <div
                v-if="submitted && !$v.editedItem.displayName.required"
                class="invalid-feedback"
              >{{$t('forms.stock.displayName-required')}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="priceIncrement">{{$t('forms.stock.priceIncrement')}}</label>
              <input
                id="priceIncrement"
                v-model.number="editedItem.priceIncrement"
                type="number"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="issueDate">{{$t('forms.stock.issueDate')}}</label>
              <b-form-datepicker id="issueDate" v-model="editedItem.issueDate" class="mb-2"></b-form-datepicker>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="issuer">{{$t('forms.stock.issuer')}}</label>
              <input
                id="issuer"
                v-model="editedItem.issuer"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="countryOfIssue">{{$t('forms.stock.countryOfIssue')}}</label>
              <input
                id="countryOfIssue"
                v-model="editedItem.countryOfIssue"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>
        </div>
        
       

         <div class="form-group">
          <label for="description">{{$t('forms.stock.description')}}</label>
          <textarea
            id="description"
            v-model="editedItem.description"
            class="form-control"
           />
        
        </div>

           <!-- <td>{{item.name}}</td>
                    <td>{{item.displayName}}</td>
                    <td>{{item.issueDate | moment("ddd, MMM Do YYYY") }}</td>
                    <td>{{item.countryOfIssue}}</td>
                    <td>{{item.issuer}}</td>
                    <td>{{item.priceIncrement}}</td> -->
        <h6>System Values</h6>
        <system-values :values="editedItem.systemValues" @change="o => editedItem.systemValues = o"></system-values>
        <div>
          <b-button class="ml-1 float-left" @click="hideModal">{{$t('forms.stock.closeButton-title')}}</b-button>
          <button type="submit" class="btn btn-success float-right" :disabled="saving">
            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
            {{$t('forms.stock.saveButton-title')}}
          </button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>